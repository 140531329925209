import React, { Component, createRef } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../global/Button"
import FakeButtonModal from "../global/FakeButtonModal"
import FakeButtonVideo from "../global/FakeButtonModalVideo"
import ButtonExternal from "../global/ButtonExternal"
import Arrow from ".././../assets/slider-arrow.inline.svg"
import RellaxWrapper from "react-rellax-wrapper"
import parse from "html-react-parser"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const HomeIntroComp = styled.section`
  width: 100%;
  position: relative;
  min-height: 750px;
  height: auto;
  /* background:var(--black); */
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background: var(--brandColor);

  @media only screen and (min-width: 1024px) {
    height: calc(100vh - var(--headerHeight));
    /* height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) ); */
    display: block;
  }
  /* 
    .rellax{
    margin-top:-9px;
    
        @media only screen and (min-width: 1024px) {
        margin-top:0;
        }

    } */

  .left {
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 4rem;
    order: 2;
    width: 100%;
    position: relative;
    background: var(--black);

    @media only screen and (min-width: 1024px) {
      position: absolute;
      width: 60%;
      order: 1;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      min-height: auto;
    }
    .image-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.6;

      .rellax {
        width: 100% !important;
      }

      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .gatsby-image-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .rellax {
      justify-content: flex-start !important;
      pointer-events: none;
      width: calc(100% - 2rem) !important;

      @media only screen and (min-width: 768px) {
        width: calc(100% - 10rem) !important;
      }

      .wrap {
        padding-left: 2rem;
        /* @media only screen and (min-width: 1024px) {
                padding-left:0;
                } */
      }

      @media only screen and (min-width: 1024px) {
        justify-content: center !important;
      }
    }

    h1 {
      color: var(--white);
      z-index: 1;
      position: relative;
    }

    .text {
      font-family: var(--font-reg);
      color: var(--white);
      z-index: 1;
      position: relative;
      line-height: 1.5rem;
      max-width: 620px;
      margin-bottom: 4rem;
      pointer-events: all;
      z-index: 100;
    }

    .button {
      flex-basis: 0 0 0 0;
      pointer-events: all;
      z-index: 100;
    }
  }
`
const SliderWrap = styled.div`
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  min-height: calc(100vh - (var(--headerHeight)));
  min-height: calc((var(--vh, 1vh) * 100) - var(--headerHeight));
  order: 1;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    min-height: 750px;
    position: absolute;
    width: 50%;
    order: 2;
  }

  .rellax {
    position: relative !important;

    @media only screen and (min-width: 1024px) {
      position: absolute !important;
    }
  }

  &:before {
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: var(--charcoal);
    transition: 0.5s all ease-in-out;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      background: var(--brandColor);
      width: calc(100% - 4rem);
    }
  }

  &.animating {
    &:before {
      transform: translateY(-100%);
    }
  }

  .slick-list {
    width: 100%;
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide div {
        height: 100%;

        div {
          height: auto;
        }
      }
    }
  }

  .slider1 {
    height: calc(45vh - (var(--headerHeight) / 2));
    position: relative;
    width: calc(100% - 4rem);
    z-index: 100;
    left: 2rem;
    margin-bottom: 2rem;
    top: 2rem;

    @media only screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      margin-bottom: 4rem;
      left: inherit;
      margin-bottom: 0;
      top: 0;
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: var(--brandColor);
      transform: translate(0, 0);
      transition: 0.5s all ease-in-out;
    }

    &.animateBG {
      &:before {
        transform: translate(1rem, 1rem);
      }
    }

    @media only screen and (min-width: 1024px) {
      &:before {
        display: none;
      }
    }

    .slide-image {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      /* height:52vh; */

      @media only screen and (min-width: 1024px) {
        display: grid !important;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
        height: 100%;
      }

      .top,
      .bottom {
        transition: 0.5s all ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;

        @media only screen and (min-width: 1024px) {
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 2;
          grid-row-end: 7;
        }

        .gatsby-image-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .top {
        z-index: 5;
        clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
      }

      .bottom {
        z-index: 0;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        transition-delay: 0.5s;
      }
    }

    .slick-active {
      .top {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
      }
      .bottom {
        clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
      }
    }
  }

  &.forwards {
    .backwards {
      display: none;
    }
  }
  &.backwards {
    .forwards {
      display: none;
    }
  }

  .slider2 {
    width: 100%;
    min-height: calc(55vh - (var(--headerHeight) / 2));
    position: relative;
    padding: 3rem 2rem;
    z-index: 800;

    @media only screen and (min-width: 1024px) {
      position: absolute;
      bottom: 4rem;
      left: 4rem;
      height: 100%;
      width: calc(100% - 11rem);
      left: 8rem;
      padding: 0;
    }

    .slide {
      position: relative;
      transition: 0.5s all ease-in-out;
      /* opacity:0; */
      transform: translateY(30px);
      pointer-events: none;

      @media only screen and (min-width: 1024px) {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .slick-active .slide {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
      z-index: 100;
    }

    .slide--inner {
      padding-bottom: 1rem;
      color: var(--white);

      @media only screen and (min-width: 1024px) {
        color: var(--black);
      }
    }

    .slick-track {
      display: flex;
      align-items: normal;

      @media only screen and (min-width: 1024px) {
        align-items: baseline;
      }
    }

    .heading {
      font-family: var(--font-bold);
      margin-bottom: 1rem;
      font-size: 2rem;

      @media only screen and (min-width: 600px) {
        font-size: 3rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 1.8rem;
      }
    }

    .text {
      font-family: var(--font-reg);
      margin-bottom: 2rem;
      max-width: 540px;
      line-height: 1.5rem;
    }

    .button {
      border: 1px solid var(--brandColor);
      color: var(--brandColor);
      z-index: 1000;

      svg {
        path {
          fill: var(--brandColor);
        }
      }

      &:after {
        background: var(--brandColor);
      }

      &:hover {
        color: var(--black);

        svg {
          path {
            fill: var(--black);
          }
        }
      }

      @media only screen and (min-width: 1024px) {
        border: 1px solid var(--black);
        color: var(--black);

        svg {
          path {
            fill: var(--black);
          }
        }

        &:after {
          background: var(--black);
        }

        &:hover {
          color: var(--brandColor);

          svg {
            path {
              fill: var(--brandColor);
            }
          }
        }
      }
    }
  }
`

const SliderArrows = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  display: none;

  @media only screen and (min-width: 1024px) {
    bottom: initial;
    display: block;
    top: 2rem;
  }

  .arrow {
    cursor: pointer;
    transition: 0.5s all ease-in-out;

    path {
      fill: var(--brandColor);

      @media only screen and (min-width: 1024px) {
        fill: var(--black);
      }
    }

    &.inactive {
      cursor: default;
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .prev {
    transform: rotate(180deg);
    margin-right: 2rem;
  }
`

export default class HomeIntro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      slideIndex: null,
      direction: "forwards",
      updateCount: 0,
      isAnimating: null,
      animateBG: null,
    }
    this.sliderWrap = createRef()
    this.slider1 = createRef()
    this.slider2 = createRef()
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      slideIndex: 0,
      isAnimating: false,
      animateBG: true,
    })
  }

  initSlider = () => {
    this.setState({ isAnimating: true })
    setInterval(
      function () {
        this.goToNextSlide()
      }.bind(this),
      5000
    )
  }

  goToNextSlide = () => {
    const slides1 = Array.from(
      this.slider1.current.querySelectorAll(".slide-image")
    )
    const slides2 = Array.from(this.slider2.current.querySelectorAll(".slide"))
    const slideIndex = this.state.slideIndex + 1
    const sliderWrap = this.sliderWrap.current

    sliderWrap.classList.add("animating")
    this.slider1.current.classList.add("forwards")
    this.slider1.current.classList.remove("backwards")

    setTimeout(function () {
      sliderWrap.classList.remove("animating")
    }, 500)

    slides1.forEach((slide, index) => {
      if (slideIndex > index) {
        slide.classList.add("behind")
      }

      if (slide.classList.contains("active")) {
        slide.classList.remove("active")
        slides1[slideIndex].classList.add("active")
      }
    })
    slides2.forEach((slide, index) => {
      if (slide.classList.contains("active")) {
        slide.classList.remove("active")
        setTimeout(function () {
          slides2[slideIndex].classList.add("active")
        }, 300)
      }
    })
    this.setState({ slideIndex: this.state.slideIndex + 1 })
  }

  goToPreviousSlide = () => {
    const slides1 = Array.from(
      this.slider1.current.querySelectorAll(".slide-image")
    )
    const slides2 = Array.from(this.slider2.current.querySelectorAll(".slide"))
    const slideIndex = this.state.slideIndex - 1
    const sliderWrap = this.sliderWrap.current

    sliderWrap.classList.add("animating")
    this.slider1.current.classList.remove("forwards")
    this.slider1.current.classList.add("backwards")

    setTimeout(function () {
      sliderWrap.classList.remove("animating")
    }, 500)

    slides1.forEach((slide, index) => {
      if (slideIndex < index) {
        slide.classList.remove("behind")
      }
      if (slide.classList.contains("active")) {
        slide.classList.remove("active")
        slides1[slideIndex].classList.add("active")
      }
    })
    slides2.forEach((slide, index) => {
      if (slide.classList.contains("active")) {
        slide.classList.remove("active")
        setTimeout(function () {
          slides2[slideIndex].classList.add("active")
        }, 300)
      }
    })
    this.setState({ slideIndex: this.state.slideIndex - 1 })
  }

  slickBeforeChange = (current, next) => {
    let direction
    if (Math.abs(next - current) == 1) {
      direction = next - current > 0 ? "forwards" : "backwards"
    } else {
      direction = next - current > 0 ? "backwards" : "forwards"
    }

    this.setState(
      {
        slideIndex: next,
        direction: direction,
        animateBG: false,
      },
      () => {}
    )
  }
  slickAfterChange = index => {
    setTimeout(
      function () {
        this.setState({
          animateBG: true,
        })
      }.bind(this),
      500
    )
  }

  render() {
    const introSlider = this.props.latest
    const data = this.props.data
    console.log(introSlider)
    const wrapperStyles = {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0",
      left: "0",
    }
    const wrapperStyles2 = {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "10",
      flexWrap: "wrap",
    }

    return (
      <HomeIntroComp className="home-intro">
        <div className="left">
          <RellaxWrapper
            className="rellax"
            desktop={-2}
            zIndex={0}
            style={wrapperStyles2}
            mobile={-2}
            tablet={-2}
            breakpoints={[500, 1024, 1201]}
            center={true}
          >
            <div className="wrap">
              <h1 className="heading--xl">{data.introHeading}</h1>
              <div className="text">{data.introText}</div>
              {data.introButton.buttonType === "external" ? (
                <ButtonExternal
                  color="white"
                  bg={"brandColor"}
                  text={data.introButton.buttonText}
                  link={data.introButton.buttonLink}
                />
              ) : data.introButton.buttonType === "internal" ? (
                <Button
                  color="white"
                  text={data.introButton.buttonText}
                  bg={"brandColor"}
                  link={data.introButton.buttonLink}
                />
              ) : data.introButton.buttonType === "launchContact" ? (
                <FakeButtonModal
                  color="white"
                  bg={"brandColor"}
                  text={data.introButton.buttonText}
                  withHover={true}
                />
              ) : data.introButton.buttonType === "launchVideoModal" ? (
                <FakeButtonVideo
                  color="white"
                  bg={"brandColor"}
                  text={data.introButton.buttonText}
                  video={data.backgroundVideo}
                  withHover={true}
                />
              ) : (
                ""
              )}
            </div>
          </RellaxWrapper>
          <div className="image-wrap">
            <RellaxWrapper
              className="rellax"
              zIndex={1}
              desktop={-1.25}
              style={wrapperStyles}
              mobile={-2}
              tablet={-2}
              breakpoints={[500, 1024, 1201]}
              center={true}
            >
              {data.backgroundVideo !== null ? (
                <video
                  controls={false}
                  autoPlay={true}
                  muted={true}
                  playsInline={true}
                  loop={true}
                >
                  <source src={data.backgroundVideo}></source>
                </video>
              ) : (
                <GatsbyImage
                  image={getImage(
                    data.backgroundImage?.localFile?.childImageSharp
                      .gatsbyImageData
                  )}
                />
              )}
            </RellaxWrapper>
          </div>
        </div>

        <SliderWrap
          className={`slider ${this.state.direction}`}
          ref={this.sliderWrap}
        >
          <RellaxWrapper
            className="rellax"
            zIndex={10}
            desktop={2.25}
            style={wrapperStyles}
            percentage="2.25"
            center={true}
            mobile={0}
            tablet={0}
            breakpoints={[500, 1024, 1201]}
          >
            <SliderArrows>
              <Arrow
                className="prev arrow"
                onClick={e => {
                  this.slider2.slickGoTo(this.state.slideIndex - 1)
                  this.sliderWrap.current.classList.remove("forwards")
                  this.sliderWrap.current.classList.add("backwards")
                }}
              />
              <Arrow
                className="next arrow"
                onClick={e => {
                  this.slider2.slickGoTo(this.state.slideIndex + 1)
                  this.sliderWrap.current.classList.remove("backwards")
                  this.sliderWrap.current.classList.add("forwards")
                }}
              />
            </SliderArrows>

            <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              fade={true}
              arrows={false}
              infinite={true}
              speed={0}
              autoplaySpeed={5000}
              autoplay={false}
              draggable={true}
              slidesToScroll={1}
              pauseOnHover={true}
              pauseOnFocus={true}
              onEdge={this.slickOnEdge}
              beforeChange={this.slickBeforeChange}
              afterChange={this.slickAfterChange}
              className={
                this.state.animateBG === true ? "slider1 animateBG" : "slider1"
              }
            >
              {introSlider.map((slide, index) => {
                return (
                  <article
                    className={
                      index === 0 ? "slide-image active" : "slide-image"
                    }
                    key={slide.slideHeading + index}
                  >
                    <div className="top">
                      <GatsbyImage
                        image={getImage(
                          slide?.acf_customFeaturedImage?.featuredImage
                            ?.localFile?.childImageSharp.gatsbyImageData
                        )}
                      />{" "}
                    </div>
                    <div className="bottom forwards">
                      <GatsbyImage
                        image={
                          introSlider[
                            index === 0
                              ? introSlider.length - 1
                              : index === introSlider.length - 1
                              ? introSlider.length - 2
                              : index - 1
                          ]?.acf_customFeaturedImage?.featuredImage?.localFile
                            ?.childImageSharp?.gatsbyImageData
                        }
                      />{" "}
                    </div>
                    <div className="bottom backwards">
                      <GatsbyImage
                        image={
                          introSlider[
                            index === 0
                              ? 1
                              : index === introSlider.length - 1
                              ? 0
                              : index + 1
                          ]?.acf_customFeaturedImage?.featuredImage?.localFile
                            ?.childImageSharp?.gatsbyImageData
                        }
                      />{" "}
                    </div>
                  </article>
                )
              })}
            </Slider>

            <Slider
              className="slider2"
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
              fade={true}
              autoplaySpeed={5000}
              autoplay={true}
              draggable={true}
              infinite={true}
              pauseOnHover={true}
              pauseOnFocus={true}
              beforeChange={this.slickBeforeChange}
            >
              {introSlider.map((slide, index) => {
                const excerpt = slide.excerpt
                console.log(slide)
                return (
                  <article
                    className={index === 0 ? "slide active" : "slide"}
                    key={slide.slideHeading + index}
                  >
                    <div className="slide--inner">
                      <div className="content">
                        <h2 className="heading">{slide.title}</h2>
                        <div className="text">
                          {excerpt.length > 250 ? (
                            <p>
                              {parse(excerpt.substring(0, 250) + "...</p>")}
                            </p>
                          ) : (
                            <p>{parse(excerpt)}</p>
                          )}
                        </div>
                        <Button
                          className="viewButton"
                          color="black"
                          bg="black"
                          text={"View post"}
                          link={slide.link}
                          prefix={slide?.prefix[0]?.slug}
                        />
                      </div>
                    </div>
                  </article>
                )
              })}
            </Slider>
          </RellaxWrapper>
        </SliderWrap>
      </HomeIntroComp>
    )
  }
}
