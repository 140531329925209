import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { default as Slick } from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import getListingSlug from "../../utils/getListingSlug"

const AdvertBannerWrapper = styled.div`
  width: 100%;
  background: ${props => props.bgColor};
  padding: 4rem 0;
`

export default function AdvertBanner(data) {
  const ads = data.data
  return (
    <Slick
      dots={false}
      infinite={true}
      fade={true}
      speed={500}
      arrows={false}
      slidesToShow={1}
      autoplay={true}
      slidesToScroll={1}
      pauseOnHover={false}
      adaptiveHeight={true}
      className="slick-parent"
    >
      {ads.map((ad, index) => {
        return (
          <AdvertBannerWrapper
            bgColor={ad.chooseAdvert.acf_adverts.adColour}
            key={`advert-${index}`}
          >
            <div className="wrapper">
              {ad.chooseAdvert.acf_adverts.internalOrExternalLink ===
              "internal" ? (
                <Link
                  className="homepageBannerClickTrack"
                  to={getListingSlug(ad.chooseAdvert.acf_adverts.adPageLink)}
                >
                  <GatsbyImage
                    className="image-wrapper"
                    image={
                      ad.chooseAdvert.acf_adverts.advertImage.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </Link>
              ) : (
                <a
                  className="homepageBannerClickTrack"
                  href={ad.chooseAdvert.acf_adverts.adPageLinkExternal}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <GatsbyImage
                    className="image-wrapper"
                    image={
                      ad.chooseAdvert.acf_adverts.advertImage.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </a>
              )}
            </div>
          </AdvertBannerWrapper>
        )
      })}
    </Slick>
  )
}
