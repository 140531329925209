import React from 'react'
import styled from 'styled-components';
import parse from 'html-react-parser'
import AnimatedImage from '../global/AnimatedImage'
import ButtonSnipcart from './ButtonSnipcart';

const FlexibleImageText = styled.section`
width:100%;
position: relative;
margin-bottom:8rem;
margin-top:8rem;

    .wrapper{
        @media only screen and (min-width: 1024px) {
        display:grid;
        grid-template-columns:1fr 4rem 1fr;
        }
    }

    .text-wrap{
    margin-bottom:2rem;

        @media only screen and (min-width: 1024px) {
        margin-bottom:0;
        }

    }

    .text-wrap,
    .image{
    /* width:50%; */
    position:relative;
    display:block;
    height:100%;
    grid-row-start:1;
    }

    .heading--l{
        margin-bottom:2rem;
    }

    .text{
    margin-bottom:2rem;
    line-height:1.5rem;

        ul li,
        p{
        line-height:1.5rem;
        margin-bottom:1rem;

            &:last-child{
            margin-bottom:0;
            }

        }

        a{
        color:var(--black);
        font-family:var(--font-bold);
        }
    }

    .image{
    
        .gatsby-image-wrapper{
        /* max-height: 400px; */
        }
        
    }
    

    .tlir{
        .text-wrap{
        /* order:1; */
        /* padding-right:4rem; */
        grid-column-start:1;
        grid-column-end:2;
        }

        .image{
        /* order:2; */
        grid-column-start:3;
        grid-column-end:4;
        }
    }
    .iltr{
        .text-wrap{
        /* order:2;
        padding-left:4rem; */
        grid-column-start:3;
        grid-column-end:4
        }

        .image{
        /* order:1; */
        grid-column-start:1;
        grid-column-end:2;
        }
    }

`


export default function FlexImageText({
    alignment,
    button,
    heading,
    text,
    image,
    isShippable,
    isTaxable
}) {

 
   
    return (
        <FlexibleImageText>
           <div className={alignment +" wrapper"} >
               <div className="text-wrap">
                   <h2 className="heading--l">{heading}</h2>
                   <div className="text">{parse(text)}</div>
                    <ButtonSnipcart 
                        itemId={button.itemId}
                        itemPrice={button.itemPrice}
                        itemName={button.itemName}
                        color="black"
                        bg="brandColor"
                        isShippable={isShippable}
                        isTaxable={isTaxable}
                    />
               </div>
               <div className="image">
                   <AnimatedImage
                        imgSrc={image.localFile.childImageSharp.gatsbyImageData}
                        offsetX={'1rem'}
                        offsetY={'1rem'}
                        color={'brandColor'}
                    />
               </div>

           </div>
        </FlexibleImageText>
    )
}
