import React, { useState } from "react"
import styled from "styled-components"
import Arrow from "../../assets/button-arrow.inline.svg"
import VideoModal from "./VideoModal"

const ButtonStyled = styled.span`
  position: relative;
  z-index: 10;
  display: inline-flex;
  width: auto;
  flex-wrap: wrap;

  &.fakeButton div {
    text-decoration: none;
    padding: 10px 15px;
    font-family: var(--font-reg);
    transition: 0.5s all ease-in-out;
    border: 1px solid var(${props => props.color});
    color: var(${props => props.color});
    display: inline;
    overflow: hidden;
    position: relative;
    cursor: ${props => props.cursor};

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      /* top:-10px; */
      left: 0;
      width: 100%;
      height: 100%;
      /* height:calc(100% + 20px); */
      transform: translateX(-100%);
      background: var(${props => props.color});
      transition: 0.5s all ease-in-out;
      z-index: -1;
    }

    svg {
      margin-left: 2rem;

      path {
        transition: 0.5s all ease-in-out;
        fill: var(${props => props.color});
      }
    }

    &:hover {
      color: var(${props => props.invert});
      border: 1px solid var(${props => props.bg});

      svg path {
        fill: var(${props => props.invert});
      }
      &:after {
        transform: translateX(${props => props.hover});
      }
    }
  }
`

export default function FakeButtonVideo(data) {
  const [modalActive, setModalActive] = useState(false)
  const handleModal = () => {
    setModalActive(!modalActive)
  }
  return (
    <div>
      <ButtonStyled
        className={
          data.ignoreStyles !== true
            ? data.className + " fakeButton"
            : data.className
        }
        color={"--" + data.color}
        invert={data.color === "white" ? "--black" : "--white"}
        hover={data.withHover === true ? "0" : "-100%"}
        cursor={data.withHover === true ? "pointer" : "normal"}
        onClick={handleModal}
      >
        <div className="button">
          {data.text}
          {data.ignoreStyles !== true ? <Arrow /> : ""}
        </div>
      </ButtonStyled>

      <VideoModal
        isActive={modalActive}
        toggleLoginModal={handleModal}
        video={data.video}
      />
    </div>
  )
}
