import React from "react"
import HomeIntro from "../components/homepage/HomeIntro"
import AdvertBanner from "../components/homepage/AdvertBanner"
import FeaturedBusiness from "../components/homepage/FeaturedBusiness"
import GridCardSlider from "../components/global/GridCardSlider"
// import InstaSlider from '../components/global/InstaSlider'
import { graphql } from "gatsby"
import Seo from "../components/seo"
import FlexImageTextSnipcart from "../components/global/FlexImageTextSnipcart"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ButtonExternal from "../components/global/ButtonExternal"
import Button from "../components/global/Button"

const MapBlock = styled.div`
  .text-wrapper {
    max-width: 1040px;
    margin: 0 auto;
    padding: 4rem 2rem 2rem 2rem;

    .heading {
      margin-bottom: 2rem;
      width: 100%;
      z-index: 10;
      text-align: center;
      font-family: "Conv_Resolve-ExtraboldCn";
      text-transform: uppercase;
      font-size: 2.6rem;
      letter-spacing: 0.16rem;

      @media only screen and (min-width: 760px) {
        font-size: 4rem;
        letter-spacing: 0.25rem;
      }
    }

    .text-image-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      position: relative;
    }

    .gatsby-image-wrapper {
      grid-column-start: 1;
      grid-column-end: 4;
      max-width: 300px;
      margin: 0 auto;
      border-radius: 1rem;

      @media only screen and (min-width: 760px) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
    .buttons {
      display: flex;
      gap: 1rem;
    }
    .text {
      font-family: var(--font-reg);
      color: var(--black);
      z-index: 1;
      position: relative;
      /* margin-bottom: 4rem; */
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 4;

      @media only screen and (min-width: 760px) {
        grid-column-start: 2;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export default function Home({ data }) {
  const seo = data.seo.seo

  const newsCats = data.newsCats.wpChildren.nodes.map(item => item.slug)

  // Reformat data for GridCardSlider
  const latestPostsArray = []
  data.latest.nodes.map((item, index) => {
    const prefix = item.categories.nodes.filter(cat => {
      if (!newsCats.includes(cat.slug) && cat.slug !== "news") {
        return cat.slug
      }
    })

    return latestPostsArray.push({
      title: item.title,
      excerpt: item.excerpt,
      link: item.slug + "/",
      prefix: prefix,
      acf_customFeaturedImage: item.acf_customFeaturedImage,
    })
  })
  const foodDrinkArray = []
  data.foodDrink.acf_homepage.foodDrinksSlider.map((item, index) => {
    return foodDrinkArray.push({
      title: item.choosePost.title,
      excerpt: item.choosePost.excerpt,
      prefix: "food-and-drink",
      link: item.choosePost.slug,
      acf_customFeaturedImage: item.choosePost.acf_customFeaturedImage,
    })
  })
  const entertainmentCultureArray = []
  data.entertainmentCulture.acf_homepage.entertainmentCultureSlider.map(
    (item, index) => {
      return entertainmentCultureArray.push({
        title: item.choosePost.title,
        excerpt: item.choosePost.excerpt,
        link: item.choosePost.slug,
        prefix: "entertainment-and-culture",
        acf_customFeaturedImage: item.choosePost.acf_customFeaturedImage,
      })
    }
  )
  const shoppingLifestyleArray = []
  data.shoppingLifestyle.acf_homepage.shoppingLifestyleSlider.map(
    (item, index) => {
      return shoppingLifestyleArray.push({
        title: item.choosePost.title,
        excerpt: item.choosePost.excerpt,
        link: item.choosePost.slug,
        prefix: "shopping-and-lifestyle",
        acf_customFeaturedImage: item.choosePost.acf_customFeaturedImage,
      })
    }
  )

  const intLink = process.env.GATSBY_SITENAME == "York" ? "york" : "leeds"
  const extLink = process.env.GATSBY_SITENAME == "York" ? "leeds" : "york"
  const intColor = process.env.GATSBY_SITENAME == "York" ? "red" : "yellow"
  const extColor = process.env.GATSBY_SITENAME == "York" ? "yellow" : "red"

  return (
    <div>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={data.og.options.acf_options.ogHome.mediaItemUrl}
      />

      <HomeIntro data={data.intro.acf_homepage} latest={latestPostsArray} />
      <MapBlock>
        <div className="text-wrapper">
          <h2 className="heading">Discover Yorkshire with Us</h2>
          <div className="text-image-wrapper">
            <GatsbyImage
              image={data.map.childImageSharp.gatsbyImageData}
              alt={"Map"}
            />
            <div className="text">
              <p>
                {console.log(
                  "test",
                  process.env.GATSBY_SITENAME,
                  intLink,
                  extLink,
                  intColor,
                  extColor
                )}
                Come with us to discover Yorkshire from the major cities of
                Leeds and York. To make it easy to navigate, we have split the
                county into two halves, exploring the west from Leeds and the
                east from York. Scroll down to the choose the area you'd like to
                visit, and use the map to explore.
              </p>
              <p>
                From exciting local independents and hidden gems to renowned
                landmarks and features on what to do, we share the best local
                experiences in each Yorkshire area. Our unique approach has been
                shaped over 10 years so that we can give people the inside
                information that inspires everyone to explore the very best of
                what this great county has to offer.
              </p>

              <div className="buttons">
                <ButtonExternal
                  color={"black"}
                  bg={extColor}
                  text={`View ${
                    extLink.charAt(0).toUpperCase() + extLink.slice(1)
                  } independents`}
                  link={`https://${extLink}.independentlife.co.uk/areas/${extLink}`}
                />
                <Button
                  color={"black"}
                  bg={intColor}
                  text={`View ${
                    intLink.charAt(0).toUpperCase() + intLink.slice(1)
                  } independents`}
                  link={`https://${intLink}.independentlife.co.uk/areas/${intLink}`}
                />
              </div>
            </div>
          </div>
        </div>
      </MapBlock>
      {/* <GridCardSlider
        sectionTitle="Latest Articles"
        linkPrefix={""}
        data={latestPostsArray}
        bgPosition="bottom"
        bgSize="50%"
        bgColor="charcoal"
      /> */}
      <FeaturedBusiness
        data={data.featuredBusiness.acf_homepage.chooseFeaturedBusiness}
      />

      {data.publication.options.acf_options.showOrHideSection === "show" ? (
        <FlexImageTextSnipcart
          alignment={"iltr"}
          backgroundColour={"var(--brandColor)"}
          heading={data.publication.options.acf_options.publicationHeading}
          text={data.publication.options.acf_options.publicationText}
          image={data.publication.options.acf_options.publicationImage}
          button={
            data.publication.options.acf_options.publicationSnipcartButton
          }
          isShippable={true}
          isTaxable={false}
        />
      ) : (
        ""
      )}

      <AdvertBanner data={data.advert.acf_homepage.advertSlider} />
      <GridCardSlider
        sectionTitle="Food &amp; Drink"
        linkPrefix={""}
        data={foodDrinkArray}
      />
      <GridCardSlider
        sectionTitle="Entertainment &amp; Culture"
        linkPrefix={""}
        data={entertainmentCultureArray}
        bgPosition="bottom"
        bgSize="50%"
        bgColor="grey"
      />
      <GridCardSlider
        sectionTitle="Shopping &amp; Lifestyle"
        linkPrefix={""}
        data={shoppingLifestyleArray}
        bgPosition="bottom"
        bgSize="100%"
        bgColor="grey"
      />
      {/* <InstaSlider 
        sectionTitle='Instagram' 
        linkPrefix={''} 
        bgPosition="bottom"
        bgSize="100%"
        bgColor="grey"
        isInsta={true}
        url={data.instaURL.options.acf_options.instagram}
      /> */}
    </div>
  )
}

export const query = graphql`
  {
    og: wp {
      options {
        acf_options {
          ogHome {
            mediaItemUrl
          }
        }
      }
    }
    map: file(name: { eq: "indy-map" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    publication: wp {
      options {
        acf_options {
          publicationHeading
          publicationText
          showOrHideSection
          publicationSnipcartButton {
            itemPrice
            itemName
            itemId
          }
          publicationImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    seo: wpPage(isFrontPage: { eq: true }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }

    #================================
    # News Cats
    #================================

    newsCats: wpCategory(name: { eq: "News" }) {
      wpChildren {
        nodes {
          name
          slug
        }
      }
    }

    #================================
    # INTRO SECTION
    #================================
    intro: wpPage(isFrontPage: { eq: true }) {
      id
      acf_homepage {
        introText
        introHeading
        introButton {
          buttonLink
          buttonType
          buttonText
        }
        backgroundVideo
        backgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        introSlider {
          slideHeading
          slideText
          slideImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          slideButtonText
          slideButtonLink
        }
      }
    }

    #================================
    # LATEST ARTICLES SECTION
    #================================
    latest: allWpPost(limit: 10, sort: { order: DESC, fields: date }) {
      nodes {
        title
        excerpt
        slug
        categories {
          nodes {
            slug
          }
        }
        acf_customFeaturedImage {
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    #================================
    # FEATURED BUSINESS SECTION
    #================================
    featuredBusiness: wpPage(isFrontPage: { eq: true }) {
      acf_homepage {
        chooseFeaturedBusiness {
          ... on WpListing {
            id
            title
            link
            slug
            listingsTagLocation {
              nodes {
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
            acf_listings {
              address
              phone
              blackCardPromotion
              fullText
              image1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }

    #================================
    # ADVERT SECTION
    #================================
    advert: wpPage(isFrontPage: { eq: true }) {
      id
      acf_homepage {
        advertSlider {
          chooseAdvert {
            ... on WpAdvert {
              id
              title
              acf_adverts {
                adCategory {
                  pickType {
                    slug
                  }
                  pickLocation {
                    slug
                  }
                  pickCategory {
                    slug
                  }
                }
                adColour
                adPageLink {
                  ... on WpListing {
                    link
                    slug
                    listingsTagLocation {
                      nodes {
                        slug
                        wpParent {
                          node {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
                adPageLinkExternal
                internalOrExternalLink
                advertImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #================================
    # Food & Drink
    #================================
    foodDrink: wpPage(isFrontPage: { eq: true }) {
      id
      acf_homepage {
        foodDrinksSlider {
          choosePost {
            ... on WpPost {
              id
              title
              link
              excerpt
              slug
              acf_customFeaturedImage {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #================================
    # Entertainment & Culture
    #================================
    entertainmentCulture: wpPage(isFrontPage: { eq: true }) {
      id
      acf_homepage {
        entertainmentCultureSlider {
          choosePost {
            ... on WpPost {
              id
              title
              link
              excerpt
              slug
              acf_customFeaturedImage {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #================================
    # Shopping & Lifestyle
    #================================
    shoppingLifestyle: wpPage(isFrontPage: { eq: true }) {
      id
      acf_homepage {
        shoppingLifestyleSlider {
          choosePost {
            ... on WpPost {
              id
              title
              link
              excerpt
              slug
              acf_customFeaturedImage {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    instaURL: wp {
      options {
        acf_options {
          instagram
        }
      }
    }
  }
`
