import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import ButtonForm from "./ButtonForm"
import { CgClose } from "react-icons/cg"
import { StaticQuery, graphql } from "gatsby"

const VideoModalStyled = styled.div`
  opacity: ${props => (props.showModal === false ? "0" : "1")};
  pointer-events: ${props => (props.showModal === false ? "none" : "all")};
  z-index: 99999999999999999999 !important;
  display: flex;

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1000000;
    color: white;
    background: none;
    font-size: 27px;
    cursor: pointer;
  }

  .centered {
    background: none !important;
    width: 90vw !important;
    opacity: ${props => (props.showModal === false ? "0" : "1")};
    transform: ${props =>
      props.showModal === false ? "translateY(200px)" : "translateY(0px)"};

    video {
      width: 100%;
    }
  }
`

class VideoModal extends React.Component {
  state = {
    modal: this.props.isActive,
  }

  constructor(props) {
    super(props)
    this.videoModalRef = React.createRef()
    console.log(props)
  }

  componentDidMount() {
    const isClient =
      typeof document !== "undefined" && typeof window !== "undefined"
    let header

    if (isClient) {
      header = document.querySelector("#header")
      header.parentNode.after(this.videoModalRef.current)
      window.addEventListener("resize", this.setOverflowOnModal)
    }

    this.setOverflowOnModal()
  }

  componentDidUpdate() {
    this.setOverflowOnModal()
  }

  componentWillUnmount() {
    const isClient =
      typeof document !== "undefined" && typeof window !== "undefined"

    if (isClient) {
      window.removeEventListener("resize", this.setOverflowOnModal)
    }
  }

  setOverflowOnModal = () => {
    const outerH = this.videoModalRef.current.offsetHeight
    const innerH =
      this.videoModalRef.current.querySelector(".centered").offsetHeight

    if (innerH > outerH) {
      this.videoModalRef.current.classList.add("overflow-required")
    } else {
      this.videoModalRef.current.classList.remove("overflow-required")
    }
  }

  toggleLoginModalAndControl = () => {
    this.props.toggleLoginModal()
    this.props.toggleIsLoggedIn()
  }

  toggleModal = () => {
    this.props.toggleLoginModal()
  }
  render() {
    // if (isLoggedIn()) {
    //   navigate(`/app/profile`)
    // }

    return (
      <VideoModalStyled
        className="videoModal modal"
        ref={this.videoModalRef}
        showModal={this.props.isActive}
      >
        <button
          aria-label="Close Popup"
          className="close"
          onClick={this.toggleModal}
        >
          <CgClose />
        </button>
        <div className="centered">
          <video src={this.props.video} controls />
        </div>
      </VideoModalStyled>
    )
  }
}

export default VideoModal
